require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import $ from 'jquery';

import "bootstrap"
import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/application";

require('packs/custom');
require('packs/other');
