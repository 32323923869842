$(window).scroll(function() {
var height = $(window).scrollTop();
/*Если сделали скролл на 100px задаём новый класс для header*/
if(height > 450){
$('nav').addClass('nav-fixed');
} else{
/*Если меньше 100px удаляем класс для header*/
$('nav').removeClass('nav-fixed');
}
});

$(document).on('turbolinks:load', function() {
  var object = $("#carouselExampleControls .carousel-inner .carousel-item")[0];
  $(object).addClass("active");
  $("#about").click(function(){
    $(this).addClass('active');
    $('#collapseAbout').addClass('show');
    $('#directors').removeClass('active');
    $('#history').removeClass('active');
    $('#life').removeClass('active');
    $('#work').removeClass('active');
    $('#tale').removeClass('active');
    $('#collapseHistory').removeClass('show');
    $('#collapseLife').removeClass('show');
    $('#collapseWork').removeClass('show');
    $('#collapseTale').removeClass('show');
    $('#collapseDirectors').removeClass('show');
  });

  $("#history").click(function(){
    $(this).addClass('active');
    $('#collapseHistory').addClass('show');
    $('#directors').removeClass('active');
    $('#about').removeClass('active');
    $('#life').removeClass('active');
    $('#work').removeClass('active');
    $('#tale').removeClass('active');
    $('#collapseAbout').removeClass('show');
    $('#collapseLife').removeClass('show');
    $('#collapseWork').removeClass('show');
    $('#collapseTale').removeClass('show');
    $('#collapseDirectors').removeClass('show');
  });

  $("#life").click(function(){
    $(this).addClass('active');
    $('#collapseLife').addClass('show');
    $('#directors').removeClass('active');
    $('#about').removeClass('active');
    $('#history').removeClass('active');
    $('#work').removeClass('active');
    $('#tale').removeClass('active');
    $('#collapseAbout').removeClass('show');
    $('#collapseHistory').removeClass('show');
    $('#collapseWork').removeClass('show');
    $('#collapseTale').removeClass('show');
    $('#collapseDirectors').removeClass('show');
  });

  $("#work").click(function(){
    $(this).addClass('active');
    $('#collapseWork').addClass('show');
    $('#directors').removeClass('active');
    $('#about').removeClass('active');
    $('#history').removeClass('active');
    $('#life').removeClass('active');
    $('#tale').removeClass('active');
    $('#collapseAbout').removeClass('show');
    $('#collapseHistory').removeClass('show');
    $('#collapseLife').removeClass('show');
    $('#collapseTale').removeClass('show');
    $('#collapseDirectors').removeClass('show');
  });

  $("#tale").click(function(){
    $(this).addClass('active');
    $('#collapseTale').addClass('show');
    $('#directors').removeClass('active');
    $('#about').removeClass('active');
    $('#history').removeClass('active');
    $('#life').removeClass('active');
    $('#work').removeClass('active');
    $('#collapseAbout').removeClass('show');
    $('#collapseHistory').removeClass('show');
    $('#collapseLife').removeClass('show');
    $('#collapseWork').removeClass('show');
    $('#collapseDirectors').removeClass('show');
  });
  $("#directors").click(function(){
    $(this).addClass('active');
    $('#collapseDirectors').addClass('show');
    $('#about').removeClass('active');
    $('#history').removeClass('active');
    $('#life').removeClass('active');
    $('#work').removeClass('active');
    $('#tale').removeClass('active');
    $('#collapseAbout').removeClass('show');
    $('#collapseHistory').removeClass('show');
    $('#collapseLife').removeClass('show');
    $('#collapseWork').removeClass('show');
    $('#collapseTale').removeClass('show');
  });

  $('#myInput').click(function() {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($('#text').text()).select();
    document.execCommand("copy");
    $temp.remove();
  });
  $('#femail').click(function() {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($('#email').text()).select();
    document.execCommand("copy");
    $temp.remove();
  });
  (function(document, history, location) {
      var HISTORY_SUPPORT = !!(history && history.pushState);

      var anchorScrolls = {
        ANCHOR_REGEX: /^#[^ ]+$/,
        OFFSET_HEIGHT_PX: 80,

        /**
         * Establish events, and fix initial scroll position if a hash is provided.
         */
        init: function() {
          this.scrollToCurrent();
          $(window).on('hashchange', $.proxy(this, 'scrollToCurrent'));
          $('body').on('click', 'a', $.proxy(this, 'delegateAnchors'));
        },

        /**
         * Return the offset amount to deduct from the normal scroll position.
         * Modify as appropriate to allow for dynamic calculations
         */
        getFixedOffset: function() {
          return this.OFFSET_HEIGHT_PX;
        },

        /**
         * If the provided href is an anchor which resolves to an element on the
         * page, scroll to it.
         * @param  {String} href
         * @return {Boolean} - Was the href an anchor.
         */
        scrollIfAnchor: function(href, pushToHistory) {
          var match, anchorOffset;

          if (!this.ANCHOR_REGEX.test(href)) {
            return false;
          }

          match = document.getElementById(href.slice(1));

          if (match) {
            anchorOffset = $(match).offset().top - this.getFixedOffset();
            $('html, body').animate({
              scrollTop: anchorOffset
            });

            // Add the state to history as-per normal anchor links
            if (HISTORY_SUPPORT && pushToHistory) {
              history.pushState({}, document.title, location.pathname + href);
            }
          }

          return !!match;
        },

        /**
         * Attempt to scroll to the current location's hash.
         */
        scrollToCurrent: function(e) {
          if (this.scrollIfAnchor(window.location.hash) && e) {
            e.preventDefault();
          }
        },

        /**
         * If the click event's target was an anchor, fix the scroll position.
         */
        delegateAnchors: function(e) {
          var elem = e.target;

          if (this.scrollIfAnchor(elem.getAttribute('href'), true)) {
            e.preventDefault();
          }
        }
      };

      $(document).ready($.proxy(anchorScrolls, 'init'));
    })(window.document, window.history, window.location);
});
